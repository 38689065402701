<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-button style="float: right" type="primary" size="small" @click="$router.back()">返回</el-button>

        <el-descriptions title="用户信息" direction="vertical" :column="4" border>
            <el-descriptions-item label="用户名">{{ user.account }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ user.mobile }}</el-descriptions-item>
            <el-descriptions-item label="会员等级" :span="2">{{ user.user_member.download_member_name }}</el-descriptions-item>
            <el-descriptions-item label="会员时长">
                {{ user.user_member.start_time }} ~ {{ user.user_member.end_time }}
            </el-descriptions-item>
        </el-descriptions>

        <div style="margin-top: 30px;">
            <h2 class="el-descriptions__title" style="margin-bottom: 20px;">历史下载记录</h2>
            <tp-table
                :isNeedSerialNumber="true"
                :tableData="list"
                :columns="columns"
                :totalNum="total"
                :current-page.sync="currentPage"
                :pageSize.sync="page_size"
            />
        </div>
    </div>
</template>

<script>
import {getList, getAllMember, detail, getDownloadUserDetailAPI, getDownloadLogAPI} from './api'

const columns = [
    {
        label: '资源名称',
        prop: 'title',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['file_name'] || '-'}</p>
        }
    },
    {
        label: '下载时间',
        prop: 'day',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['day']}</p>
        }
    },
]
export default {
    name: 'List',

    data() {
        return {
            user: {},

            columns,
            currentPage: 1,
            page_size: 10,
            total: 0,
            list: [],
            memberList: [
                {
                    id: 0,
                    title: '请选择'
                }
            ],
            searchData: {
                uid: 0,
                type: '',
            }
        }
    },
    provide() {
        return {
            context: this
        }
    },
    watch: {
        currentPage() {
            this.getList()
        },
        page_size() {
            this.getList()
        }
    },
    mounted() {
        this.searchData.uid = this.$route.params.id

        this.getList()
        this.getUserDetail(this.$route.params.id)
    },

    methods: {
        async getUserDetail(id) {
            this.user = await detail(id)
        },

        async getList() {
            const {uid, type} = this.searchData
            let params = {page: this.currentPage, page_size: this.page_size, uid, type}
            const res = await getDownloadLogAPI(params)
            this.list = res.data || []
            this.total = res.total || 0
        },
        goEdit(id) {
            this.$router.push({name: 'MemberEdit', params: {id: id || undefined}})
        },
        async getAllMember() {
            this.memberList = await getAllMember({})
        },
        reset() {
            Object.assign(this._data.searchData, this.$options.data().searchData)
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;

    .header-search {
        .el-form /deep/ {
            .el-form-item {
                margin-right: 35px;

                .el-input {
                    width: 250px;
                }

                .el-date-editor {
                    .el-range-separator {
                        padding: 0;
                    }

                    .el-range-input {
                        width: 35%;
                    }
                }
            }
        }
    }

    .el-divider--horizontal {
        margin: 0 0 20px;
    }
}
</style>
